<template>
  <!-- Subscribe -->
  <div
    :class="classes"
    v-if="!loading">
    <!-- Error banner -->
    <ui-banner
      :description="errorsLogin"
      intent="danger"
      v-if="hasErrors"
    />

    <section class="user-forms-subscribe__google flow">
      <div id="google-connect"></div>
    </section>

    <div class="user-forms-subscribe__separator">
      <p class="user-forms-subscribe__or">{{ $t('user-portal.or') }}</p>
    </div>

    <form
      class="user-forms-subscribe__body flow"
      :key="key"
      @submit="onSubmit">
      <!-- Guest form -->
      <div class="user-forms-subscribe__guest flow">
        <div class="group">
          <!-- Firstname -->
          <forms-input
            :autofocus="autofocus"
            autocomplete
            :errors="getErrors('firstname')"
            name="given-name"
            :placeholder="$t('user-portal.user_firstname_placeholder')"
            ref="firstname"
            required
            @input="onRemoveError('firstname')"
            v-model="user.firstname"
          >{{ $t('user-portal.user_firstname') }}</forms-input>

          <!-- Lastname -->
          <forms-input
            autocomplete
            :errors="getErrors('lastname')"
            name="family-name"
            ref="lastname"
            required
            :placeholder="$t('user-portal.user_lastname_placeholder')"
            @input="onRemoveError('lastname')"
            v-model="user.lastname"
          >{{ $t('user-portal.user_lastname') }}</forms-input>
        </div>

        <div class="group">
          <!-- Email -->
          <forms-input
            autocomplete
            :errors="getErrors('email')"
            name="email"
            :placeholder="$t('user-portal.user_email_placeholder')"
            ref="email"
            :required="true"
            type="email"
            @input="onRemoveError('email')"
            v-model="user.email"
          >{{ $t('user-portal.user_email') }}</forms-input>

          <!-- Phone -->
          <forms-input
            autocomplete
            :errors="getErrors('phone')"
            :hint="$t('user-portal.user_phone_number_hint')"
            name="phone"
            :placeholder="$t('user-portal.user_phone_number_placeholder')"
            ref="phone"
            :required="false"
            @input="onRemoveError('phone')"
            v-if="false"
            v-model="user.phone"
          >{{ $t('user-portal.user_phone_number') }}</forms-input>
        </div>
      </div>

      <!-- Password -->
      <forms-input
        :errors="getErrors('password')"
        name="password"
        :placeholder="$t('user-portal.user_password_placeholder')"
        ref="password"
        :icon-post="passwordIconPost"
        icon-post-interactive
        required
        :type="passwordType"
        @iconClick="onPasswordIconClick"
        @input="onRemoveError('password')"
        v-model="user.password"
      >{{ $t('user-portal.user_password') }}</forms-input>

      <!-- Password Confirm -->
      <!-- <forms-input
        :errors="getErrors('password_confirmation')"
        name="password-confirmation"
        :placeholder="$t('user-portal.user_password_confirmation_placeholder')"
        ref="password-confirmation"
        required
        type="password"
        @input="onRemoveError('password_confirmation')"
        v-model="user.passwordConfirmation"
      >{{ $t('user-portal.user_password_confirmation') }}</forms-input> -->

      <!-- Terms -->
      <!-- <forms-checkbox
        :size="$pepper.Size.L"
        v-if="hasSubscribe"
        v-model="user.optInTc">
        <div class="user-forms-subscribe__terms-content">
          <span
            class="user-forms-subscribe__terms"
            v-html="$t('user-portal.user_terms_label')"
          ></span>

          <span
            class="user-forms-subscribe__terms-link"
            @click.stop.prevent="() => showTerms = true"
            v-html="$t('user-portal.user_terms_link')"
          ></span>
        </div>
      </forms-checkbox> -->

      <!-- Newsletter -->
      <!-- <div v-if="hasSubscribe">
        <forms-checkbox 
          :size="$pepper.Size.L"
          v-model="user.optIn">
          <div class="user-forms-subscribe__terms-content">
            <span
              class="user-forms-subscribe__terms"
              v-html="$t('user-portal.user_newsletter')"
            ></span>
          </div>
        </forms-checkbox>
      </div> -->
    </form>

    <modal-terms
      :visible="showTerms"
      @close="() => showTerms = false"
      v-if="showTerms"
    />
  </div>
</template>

<script>
import ModalTerms from '@/components/modals/terms'
import UiBanner from '@/components/ui/banner'

import { initGoogleLogin } from '@/helpers/google-login.js'

import MixinFields from '@/helpers/fields'
import MixinErrors from '@/helpers/errors'

import SaylStore from '@/bootstrap/stores'

export default {
  name: 'FormsSubscribe',

  components: {
    ModalTerms,
    UiBanner,
  },

  inject: [
    '$embed',
    '$localStorage',
    '$redirect',
    '$user',
  ],

  mixins: [
    MixinErrors,
    MixinFields
  ],

  model: {
    prop: 'user',
    event: 'change',
  },

  props: {
    autofocus: {
      type: Boolean,
      default: true
    },

    errors: {
      type: Object | Array,
    },

    intent: {},

    user: {
      type: Object,
      required: true,
    },

    hasGuest: {
      type: Boolean,
      default: false,
    },

    hasSubscribe: {
      type: Boolean,
      default: true,
    },

    wallet: {
      type: Boolean, 
      default: false
    }
  },

  data() {
    return {
      isCreate: false,
      key: 1,
      optin: false,
      showTerms: false,
      showWalletTerms: false,

      bootstrap: SaylStore.bootstrap(),
      passwordType: 'password'
    }
  },

  computed: {
    errorsLogin() {
      if(this.getErrors('redirect').length > 0) {
        return this.getErrors('redirect').join(', ')
      }

      if(this.getErrors('target_embed_id').length > 0) {
        return this.getErrors('target_embed_id').join(', ')
      }
      
      if(this.getErrors('global').length > 0) {
        return this.getErrors('global').join(', ')
      }

      return this.$t('user-portal.user_subscribe_error')
    },

    classes() {
      return {
        'user-form': true,
        'user-forms-subscribe': true,
        'flow': true
      }
    },

    i18n() {
      return this.bootstrap.i18n
    },

    isCreating() {
      return this.$basil.get(this.user, 'isCreating', false)
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin'
    },

    isNewsletterActive() {
      return this.isFieldActive('has_newsletter')
    },

    isNewsletterOptout() {
      return this.isFieldActive('is_newsletter_opt_out')
    },

    isTCOptout() {
      return this.isFieldActive('is_terms_opt_out')
    },

    hasErrors() {
      return !this.$basil.isNil(this.errors) && !this.$basil.isEmpty(this.errors)
    },

    loading() {
      return this.user == null
    },

    passwordIconPost() {
      return this.passwordType === 'password' ? 'visible' : 'hidden'
    },
    
    userLang() {
      return this.$basil.get(this.$user, 'user.lang', this.i18n.locale.lang.toString())
    },
  },

  watch: {
    userLang: {
      handler() {
        let route = null
        if(this.intent) {
          route = this.$router.resolve(this.intent)
          if(route) {
            route = route.href
          } else {
            route = null
          }
        }
        initGoogleLogin(
          this.$basil.get(this.bootstrap, 'data.google.gsi.client_id', null),
          this.$basil.get(this.$embed, 'embed.id'),
          this.$redirect,
          this.userLang,
          route
        )
      }
    },
    immediate: false,
  },

  methods: {
    onPasswordIconClick(e) {
      e.preventDefault()
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },

    onSubmit(e) {
      e.preventDefault()
      return
    },
  },

  mounted() {
    this.user.password = null
    this.user.passwordConfirmation = null

    this.user.optInTc = false // this.isTCOptout
    this.user.optIn = true // this.isNewsletterOptout === true ? this.isNewsletterOptout : this.user.optIn

    if(!this.hasGuest) {
      this.isCreate = true
    }

    this.key++

    let route = null
    if(this.intent) {
      route = this.$router.resolve(this.intent)
      if(route) {
        route = route.href
      } else {
        route = null
      }
    }

    initGoogleLogin(
      this.$basil.get(this.bootstrap, 'data.google.gsi.client_id', null),
      this.$basil.get(this.$embed, 'embed.id'),
      this.$redirect,
      this.userLang,
      route
    )
  },
}
</script>
