<template>
  <div :class="classes">
    <div
      v-if="title"
      class="ui-banner__title"
    >{{ title }}</div>

    <div
      v-if="description"
      class="ui-banner__description"
      v-html="description"
    ></div>

    <div
      class="ui-banner__description"
      v-if="hasSlotDescription">
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiBanner',

  props: {
    description: {
      type: String
    },

    intent: {
      type: String,
      default: 'default',
      validator: (value) => ['danger', 'default', 'success', 'warning'].includes(value)
    },

    title: {
      type: String
    },
  },

  computed: {
    classes(){
      return {
        'ui-banner': true,
        ['-is-'+this.intent]: true
      }
    },

    hasSlotDescription() {
      return !!this.$slots.description
    },
  }
}
</script>
