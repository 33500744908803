export default {
  beforeRouteLeave(to, from, next) {
    if(!to.name.includes('sayl-user-portal.claim')) {
      let el = document.head.querySelector("style[name='claim_style']")
      if(el) {
        document.head.removeChild(el)
      }
    }
    next()
  }
}
