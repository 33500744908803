export default {
  inject: [
    '$poap'
  ],

  data() {
    return {
      errorPrefix: 'poap_claim_error'
    }
  },

  computed: {
    asset() {
      if(!this.poapLocation) {
        return null
      }

      return this.$basil.get(this.poapLocation, 'assetUrl', null)
    },

    confirmRoute() {
      return { name: 'sayl-user-portal.claim.poap.confirm' }
    },

    description() {
      return this.$basil.get(this.poapLocation, 'description', null)
    },

    geolocationRequired() {
      return this.$basil.get(this.poapLocation, 'validateGeolocation', false)
    },

    incentive() {
      return this.$basil.get(this.poapLocation, 'incentive', null)
    },

    loginRoute() {
      return { name: 'sayl-user-portal.claim.poap.login' }
    },

    name() {
      return this.$basil.get(this.poapLocation, 'name', null)
    },

    poapLocation() {
      return this.$poap.poapLocation
    },

    signupRoute() {
      return { name: 'sayl-user-portal.claim.poap.signup' }
    },
  },

  methods: {
    async view(payload) {
      return await this.$poap.viewLocation(payload)
    },

    async claim(payload) {
      return await this.$poap.claimLocation(payload)
    }
  }
}