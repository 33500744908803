const callback = (key, embedId, $redirect, locale, route) => {
  let loginUri = `${window.location.origin}/google/${embedId}/login`

  let params = [
    $redirect.redirect ? `redirect=${encodeURIComponent($redirect.redirect)}` : null,
    $redirect.target ? `target_embed_id=${encodeURIComponent($redirect.target)}` : null,
    route != null && route.replace(`/user/${embedId}`, '').length > 1 ? `intent=${encodeURIComponent(route.replace(`/user/${embedId}`, ''))}` : null,
  ].filter(p => p != null)
  
  if(params.length > 0) {
    loginUri = loginUri.concat(`?${params.join('&')}`)
  }
  
  // $console.info('Login URI for google', loginUri)
  
  google.accounts.id.initialize({
      client_id: key,
      ux_mode: "redirect",
      login_uri: loginUri
  })

  google.accounts.id.renderButton(
      document.getElementById("google-connect"),
      { 
        theme: "outline", 
        size: "large",
        width: '100%',
        locale: locale
      } // customization attributes
  )
}

const initGoogleLogin = (key, embedId, $redirect = null, locale = 'en', route = null) => {
  let el = document.querySelector("script[name='google-login']")
  
  if(el != null) {
    let lang = el.getAttribute('lang')
    if(lang !== locale) {
      document.body.removeChild(el)
      el = null
    }
  }
  
  if(el == null) {
    el = document.createElement('script')
    el.setAttribute('name', 'google-login')
    el.setAttribute('lang', locale)
    el.setAttribute('async', true)
    el.setAttribute('defer', true)

    let scriptSrc = `https://accounts.google.com/gsi/client?hl=${locale}`
    el.setAttribute('src', scriptSrc)

    el.addEventListener('load', () => callback(key, embedId, $redirect, locale, route))
    document.body.appendChild(el)
  } else {
    callback(key, embedId, $redirect, locale, route)
  }
}

export { initGoogleLogin }