<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">
    <div
      class="modal-terms__loader"
      v-if="loading">
      <ui-loader />
    </div>

    <iframe
      class="modal-terms__iframe"
      height="100%"
      ref="terms"
      loading="lazy"
      :src="url"
      width="100%"
    />
  </layout-modal>
</template>

<script>
import LayoutModal from '@/components/layouts/modal'

export default {
  name: 'TermsModal',

  components: {
    LayoutModal
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: true,
    }
  },

  computed: {
    attrs() {
      return {
        classes: {
          'modal-terms': true,
        },
        loading: this.loading,
        visible: this.visible,
      }
    },

    listeners() {
      return {
        close: this.back,
        back: this.back,
        discard: this.back,
      }
    },

    url() {
      let ret = 'https://saylcloud.com/en/page/terms'
      let url = this.$basil.get(this.oat, 'custom_tc_url')

      return !this.$basil.isNil(url) ? url : ret
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },
  },

  mounted() {
    this.loading = true
    let ref = this.$refs.terms

    if(!this.$basil.isNil(ref)) {
      let ctx = this

      ref.addEventListener('load', () => {
        ctx.loading = false
      })
    }
  },
}
</script>
