<template>
  <div 
    class="view-claim-auth" 
    @keyup="onKeyUp">
    <div class="view-claim-auth__body">
      <!-- Header -->
      <header class="view-claim-auth__header">
        <h1 class="view-claim-auth__title">{{ $t('user-portal.user_claim_signup_title')}}</h1>
        <p class="view-claim-auth__descr">{{ $t('user-portal.user_claim_signup_descr')}}</p>
      </header>

      <!-- Sign up -->
      <section class="view-claim-auth__login flow">
        <forms-subscribe
          :intent="confirmRoute"
          :errors="errors"
          v-model="user"
        />

        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          class="fill"
          :disabled="loading"
          :loading="loading"
          @click="onSubscribe"
        >{{ $t('user-portal.action_confirm') }}</actions-button>

        <forms-label>{{ $t('user-portal.user_confirm_tc_optout') }}</forms-label>
      </section>

      <!-- Login -->
      <section class="view-claim-auth__alt view-claim-auth__subscribe">
        <div class="view-claim-auth__label">{{ $t('user-portal.user_subscribe_login') }}</div>

        <actions-button 
          :href="loginRoute"
        >{{ $t('user-portal.action_signin') }}</actions-button>
      </section>
    </div>
  </div>
</template>

<script>
import MixinErrors from '@/helpers/errors'
import MixinPoapClaim from '@/modules/claim/helpers/poap-claim.js'
import MixinRouteLeave from '@/modules/claim/helpers/router-leave.js'

import FormsSubscribe from '@/modules/user/components/forms/subscribe'

export default {
  name: 'UserLoginView',

  components: {
    FormsSubscribe
  },

  inject: [
    '$localStorage',
    '$user',

    '$redirect',
  ],

  mixins: [
    MixinErrors,
    MixinPoapClaim,
    MixinRouteLeave,
  ],

  data() {
    return {
      errors: {},
      key: 1,
      loading: false,
    }
  },

  computed: {
    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    onKeyUp(ev) {
      if(ev.code === 'Enter') {
        this.onSubscribe()
      }
    },

    async onSubscribe() {
      try {
        this.loading = true
        this.errors = {}

        await this.$user.subscribe({ 
          user: this.user, 
          password: this.user.password,
        })

        this.$router.replace(this.confirmRoute).catch(() => {})
      } catch(e) {
        this.handleErrors(e)
      } finally {
        this.loading = false
        this.key++
      }
    }
  },

  mounted() {
    this.errors = {}
  }
}
</script>
